import {
	ActionFunction,
	json,
	type LoaderFunction,
	type MetaFunction,
} from "@remix-run/node";
import {
	Link,
	useLoaderData,
	useNavigation,
	useSearchParams,
} from "@remix-run/react";
import { SearchInput } from "~/components/features/SearchInput";
import { RecentStreams } from "~/components/features/home/RecentStreams";
import { PopularSongs } from "~/components/features/home/PopularSongs";
import { ActiveSingers } from "~/components/features/home/ActiveSingers";
import { Statistics } from "~/components/features/home/Statistics";
import { Navigation } from "~/components/features/home/Navigation";
import {
	SearchResponse,
	searchSingingStreams,
	SearchResult,
} from "~/models/singing-stream.server";
import {
	getRecentStreams,
	getPopularSongs,
	getActiveSingers,
	getStatistics,
} from "~/models/home.server";
import { Music } from "lucide-react";
import SiYoutube from "@icons-pack/react-simple-icons/icons/SiYoutube.mjs";
import { SITE_BASE_URL } from "~/lib/constants";
import SearchPagination from "~/components/features/SearchPagination";
import { SearchLogger } from "~/services/search-logger.server";
import SearchClickLogger from "~/components/features/SearchClickLogger";
import { loader as Loader } from "~/loader/front/home/index.server";
import { LoaderData } from "~/loader/front/home/types";
import { useVideoTimestamp } from "~/hooks/useVideoTimestamp";
import { Menu } from "@headlessui/react";
import { MoreVertical } from "lucide-react";

export const meta: MetaFunction<typeof loader> = ({ matches }) => {
	const parentMeta = matches.flatMap((match) => match.meta ?? []);
	const url = `${SITE_BASE_URL}`;
	return [
		...parentMeta,
		{
			tagName: "link",
			rel: "canonical",
			href: url,
		},
	];
};

export const loader: LoaderFunction = async (data) => {
	return await Loader(data);
};

// クリックのログ記録用のアクション関数を追加
export const action: ActionFunction = async ({ request }) => {
	const formData = await request.formData();
	const searchLogId = formData.get("searchLogId") as string;
	const itemType = formData.get("itemType") as string;
	const itemId = formData.get("itemId") as string;
	const position = parseInt(formData.get("position") as string);

	if (!searchLogId || !itemType || !itemId) {
		return json({ error: "Missing required parameters" }, { status: 400 });
	}

	await SearchLogger.logClick({
		searchLogId,
		itemType,
		itemId,
		position,
	});

	return json({ success: true });
};

export default function Index() {
	const {
		searchResults,
		recentStreams,
		popularSongs,
		activeSingers,
		statistics,
		searchLogId,
		query,
		error,
	} = useLoaderData<LoaderData>();
	const navigation = useNavigation();
	const [searchParams, setSearchParams] = useSearchParams();
	const isSearching =
		navigation.state === "loading" &&
		navigation.location?.search.includes("q=");

	return (
		<div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2 space-y-8">
				{/* ロゴとタグライン */}
				<div className="text-center mt-4 space-y-3">
					<div className="inline-flex items-center space-x-2 text-3xl sm:text-4xl font-bold">
						<Music className="w-8 h-8 text-blue-500" />
						<h1>
							VSinger Moment <i className="text-xs">Beta</i>
						</h1>
					</div>
					<p className="text-xl sm:text-2xl text-gray-600">
						推しの歌声と、めぐり逢う
					</p>
				</div>

				{/* 検索セクション */}
				<div>
					<SearchInput />
					{isSearching ? (
						<div className="mt-4 text-center text-sm text-gray-500">
							検索中...
						</div>
					) : (
						query && (
							<div className="mt-4 text-center text-sm text-gray-500">
								{searchResults.totalCount}件の検索結果
							</div>
						)
					)}
				</div>

				{/* エラーメッセージ */}
				{error && (
					<div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
						{error}
					</div>
				)}

				{/* 検索結果または通常コンテンツ */}
				{query ? (
					<div className="space-y-8">
						<div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
							<div className="p-4">
								<h2 className="text-lg font-medium text-gray-900 mb-4">
									検索結果
								</h2>
								{searchResults.totalCount > 0 ? (
									<>
										<div className="divide-y divide-gray-200">
											{searchResults.results.map((result, index) => (
												<SearchClickLogger
													key={result.singingStreamId}
													searchLogId={searchLogId}
													itemType={result.songId ? "song" : "stream"}
													itemId={result.songId || result.singingStreamId}
													position={index + 1}
												>
													<SearchResultItem
														key={result.singingStreamId}
														result={result}
													/>
												</SearchClickLogger>
											))}
										</div>
										<SearchPagination
											currentPage={searchResults.currentPage}
											totalPages={searchResults.totalPages}
											onPageChange={(page) => {
												const params = new URLSearchParams(searchParams);
												params.set("page", page.toString());
												setSearchParams(params);
											}}
										/>
									</>
								) : (
									<div className="text-center py-8 text-gray-500">
										該当する歌唱データが見つかりませんでした
									</div>
								)}
							</div>
						</div>
					</div>
				) : (
					<div className="space-y-8">
						{/* ナビゲーション */}
						<Navigation />

						{/* メインコンテンツ */}
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
							<PopularSongs songs={popularSongs} />
							<RecentStreams streams={recentStreams} />
						</div>

						{/* アクティブな歌い手 */}
						<ActiveSingers singers={activeSingers} />

						{/* 統計情報 */}
						<Statistics statistics={statistics} />
					</div>
				)}
			</div>
		</div>
	);
}

// 検索結果アイテムのコンポーネント
function SearchResultItem({ result }: { result: SearchResult }) {
	const { handleTimestampClick } = useVideoTimestamp();
	const videoId = extractYouTubeVideoId(result.videoUrl);

	const handleClick = (e: React.MouseEvent) => {
		if ((e.target as Element).closest(".menu-button")) {
			return;
		}

		if (videoId) {
			handleTimestampClick(
				e,
				result.singingStreamTimestamp,
				videoId,
				result.songTitle,
			);
		}
	};

	return (
		<div className="py-2">
			<div
				className="flex items-start space-x-3 hover:bg-gray-50 p-2 rounded-lg cursor-pointer"
				onClick={handleClick}
			>
				{result.videoThumbnailUrl && (
					<div>
						<img
							src={result.videoThumbnailUrl}
							alt=""
							className="h-20 w-32 object-cover rounded-lg"
							loading="lazy"
						/>
						<p className="mt-1 text-xs text-gray-500">
							{new Date(result.videoStreamedAt).toLocaleDateString("ja-JP")} -{" "}
							{result.singingStreamTimestamp}
						</p>
					</div>
					// <div className="flex-shrink-0">
					// 	<img
					// 		src={result.videoThumbnailUrl}
					// 		alt=""
					// 		className="h-16 w-24 object-cover rounded-lg"
					// 		loading="lazy"
					// 	/>
					// 	<span>{new Date(result.videoStreamedAt).toLocaleDateString("ja-JP")}</span>
					// </div>
				)}

				<div className="flex-1 min-w-0">
					<div className="space-y-1">
						<div className="flex items-center justify-between text-sm text-ellipsis overflow-hidden whitespace-nowrap">
							<p className="text-xs">{result.singerName}</p>
						</div>
						<p className="font-medium text-gray-900 line-clamp-2">
							{result.songTitle || result.videoTitle}
						</p>
						<p className="text-sm text-gray-500 line-clamp-1">
							{result.songOriginalArtist}
						</p>
					</div>
				</div>

				<Menu as="div" className="relative menu-button ml-2">
					<Menu.Button className="p-1 rounded-full hover:bg-gray-100">
						<MoreVertical className="w-5 h-5 text-gray-400" />
					</Menu.Button>
					<Menu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
						<Menu.Item>
							<a
								href={`/singers/${result.singerId}`}
								className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
							>
								歌い手のページへ
							</a>
						</Menu.Item>
						{result.songId && (
							<Menu.Item>
								<a
									href={`/songs/${result.songId}`}
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
								>
									楽曲のページへ
								</a>
							</Menu.Item>
						)}
						<Menu.Item>
							<a
								href={`/videos/${result.videoId}`}
								className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
							>
								動画のページへ
							</a>
						</Menu.Item>
						<Menu.Item>
							<a
								href={`${result.videoUrl}&t=${timeToSeconds(result.singingStreamTimestamp)}`}
								target="_blank"
								rel="noopener noreferrer"
								className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
							>
								YouTubeで開く
							</a>
						</Menu.Item>
					</Menu.Items>
				</Menu>
			</div>
		</div>
	);
}

function timeToSeconds(timestamp: string): number {
	if (!timestamp) return 0;
	const parts = timestamp.split(":").map(Number);
	if (parts.length === 3) {
		return parts[0] * 3600 + parts[1] * 60 + parts[2];
	}
	return parts[0] * 60 + parts[1];
}

function extractYouTubeVideoId(url: string): string | null {
	const match = url.match(/[?&]v=([^&]+)/);
	return match ? match[1] : null;
}
