import { useVideoPlayer } from "~/contexts/VideoPlayerContext";

export function timeToSeconds(timestamp: string): number {
	if (!timestamp) return 1;
	const parts = timestamp.split(":").map(Number);
	if (parts.length === 3) {
		return parts[0] * 3600 + parts[1] * 60 + parts[2];
	}
	return parts[0] * 60 + parts[1];
}

export function useVideoTimestamp() {
	const { setIsPlayerVisible, setCurrentVideo, currentVideo, player } =
		useVideoPlayer();

	const handleTimestampClick = (
		e: React.MouseEvent,
		timestamp: string,
		videoId: string,
		videoTitle: string,
	) => {
		e.preventDefault();
		const seconds = timeToSeconds(timestamp);

		// 同じ動画の場合は直接シーク
		if (currentVideo?.videoId === videoId && player) {
			player.seekTo(seconds, true);
			player.playVideo();
		} else {
			// 異なる動画の場合は新しい動画情報を設定
			setCurrentVideo({
				videoId,
				title: videoTitle,
				timestamp: seconds,
			});
		}

		// プレイヤーを表示
		setIsPlayerVisible(true);
	};

	return { handleTimestampClick };
}
