import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { json, type LoaderFunction, type LinksFunction } from "@remix-run/node";
import {
	Links,
	LiveReload,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	useLoaderData,
	useRouteError,
} from "@remix-run/react";
import { getSeoMeta } from "~/utils/seo.server";
import styles from "./tailwind.css?url";
import { SupabaseProvider } from "./utils/supabase.context";
import { SITE_BASE_URL } from "./lib/constants";
import GoogleAnalytics from "./components/shared/GoogleAnalyticsTracking";
import Footer from "./components/Footer";
import Header from "./components/layout/Header";
import {
	VideoPlayerProvider,
	useVideoPlayer,
} from "~/contexts/VideoPlayerContext";
import { useState } from "react";
import { GlobalVideoPlayer } from "~/components/shared/GlobalVideoPlayer";
import { SpeedInsights } from "@vercel/speed-insights/remix";
import { Analytics } from "@vercel/analytics/remix";

type LoaderData = {
	env: {
		SUPABASE_URL: string;
		SUPABASE_ANON_KEY: string;
		SITE_BASE_URL: string;
		GA_TRACKING_ID: string;
	};
	seoMeta: {
		totalSingers: number;
		totalSongs: number;
		totalStreams: number;
		title?: string;
		description?: string;
		image?: string;
		url?: string;
	};
};

export const meta = ({ data }: { data: LoaderData | undefined }) => {
	const { seoMeta } = data || {
		seoMeta: {
			totalSingers: 0,
			totalSongs: 0,
			totalStreams: 0,
			title: undefined,
			description: undefined,
			image: undefined,
			url: undefined,
		},
	};

	const title =
		seoMeta.title ??
		"VSinger・VTuberの歌声とのめぐり逢いをサポート - VSinger Moment";
	const description =
		seoMeta.description ??
		`${seoMeta.totalSingers}人のVSinger・VTuberによる${seoMeta.totalStreams}回の歌唱データを収録。歌枠やカバー動画をかんたんに探せて、推しの歌声と運命的な出会いが見つかります。${seoMeta.totalSongs}曲以上の楽曲データベースで、あなたの「めぐり逢い」をサポート。`;
	const image = seoMeta.image ?? "https://vsinger-moment.jp/images/ogp.png";
	const url = seoMeta.url ?? data?.env.SITE_BASE_URL;

	return [
		{ charset: "utf-8" },
		{ title: title },
		{ name: "viewport", content: "width=device-width,initial-scale=1" },
		{ name: "description", content: description },
		// OGP基本設定
		{ property: "og:type", content: "website" },
		{ property: "og:site_name", content: "VSinger Moment" },
		{ property: "og:title", content: title },
		{ property: "og:description", content: description },
		{ property: "og:image", content: image },
		{ property: "og:url", content: url },
		// Twitter Card
		{ name: "twitter:card", content: "summary_large_image" },
		{ name: "twitter:site", content: "@VSingerMoment" },
		{ name: "twitter:title", content: title },
		{ name: "twitter:description", content: description },
		{
			name: "twitter:image",
			content: "https://vsinger-moment.jp/images/ogp.png",
		},
		// その他のメタタグ
		{ name: "theme-color", content: "#3b82f6" },
		{
			name: "keywords",
			content:
				"VSinger,VTuber,歌枠,歌ってみた,カバー,歌唱,検索,データベース,推し,めぐり逢い",
		},
		{ name: "author", content: "VSinger Moment" },
		// モバイル対応
		{ name: "format-detection", content: "telephone=no" },
		{ name: "apple-mobile-web-app-capable", content: "yes" },
		{ name: "apple-mobile-web-app-status-bar-style", content: "default" },
		{ name: "apple-mobile-web-app-title", content: "VSinger Moment" },
		// 検索エンジン向け
		{ name: "robots", content: "index, follow" },
		{ name: "googlebot", content: "index, follow" },
	];
};

export const links: LinksFunction = () => [
	{ rel: "stylesheet", href: styles },
	{
		rel: "icon",
		type: "image/png",
		href: "https://vsinger-moment.jp/icon-192x192.png",
	},
	{
		rel: "apple-touch-icon",
		type: "image/png",
		href: "https://vsinger-moment.jp/apple-touch-icon-180x180.png",
	},
	// Web App Manifest
	//   { rel: "manifest", href: "/site.webmanifest" },
];

export function ErrorBoundary() {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		return (
			<html lang="ja">
				<head>
					<meta charSet="utf-8" />
					<meta name="viewport" content="width=device-width,initial-scale=1" />
					<title>エラー - VSinger Moment</title>
					<Meta />
					<Links />
				</head>
				<body>
					<div className="flex min-h-screen items-center justify-center">
						<div className="text-center">
							<h1 className="text-3xl font-bold">
								{error.status} {error.statusText}
							</h1>
							<p className="mt-2 text-gray-600">{error.data}</p>
							<a
								href="/"
								className="mt-4 inline-block text-blue-600 hover:text-blue-800"
							>
								トップページへ戻る
							</a>
						</div>
					</div>
					<Scripts />
				</body>
			</html>
		);
	}

	captureRemixErrorBoundaryError(error);
	console.error(error);

	return (
		<html lang="ja">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<title>エラー - VSinger Moment</title>
				<Meta />
				<Links />
			</head>
			<body>
				<div className="flex min-h-screen items-center justify-center">
					<div className="text-center">
						<h1 className="text-3xl font-bold">予期せぬエラーが発生しました</h1>
						<p className="mt-2 text-gray-600">
							申し訳ありませんが、もう一度お試しください。
						</p>
						<a
							href="/"
							className="mt-4 inline-block text-blue-600 hover:text-blue-800"
						>
							トップページへ戻る
						</a>
					</div>
				</div>
				<Scripts />
			</body>
		</html>
	);
}

export const loader: LoaderFunction = async ({ request }) => {
	if (!process.env.SUPABASE_URL || !process.env.SUPABASE_ANON_KEY) {
		throw new Error("Missing Supabase environment variables");
	}

	const seoMeta = await getSeoMeta();

	return json({
		env: {
			SUPABASE_URL: process.env.SUPABASE_URL,
			SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY,
			SITE_BASE_URL: SITE_BASE_URL,
			GA_TRACKING_ID: process.env.GA_TRACKING_ID || "",
		},
		seoMeta,
	});
};

const position = () => {
	const { player, isPlayerVisible } = useVideoPlayer();
	if (!player) {
		return "pt-[80px]";
	}
	return isPlayerVisible ? "pt-[82vw] md:pt-[52vw]" : "pt-[120px]";
};

function AppContent() {
	const { env } = useLoaderData<LoaderData>();

	return (
		<html lang="ja">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Meta />
				<Links />
				{/* 構造化データ */}
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify({
							"@context": "https://schema.org",
							"@type": "WebSite",
							name: "VSinger Moment",
							description: "VSinger・VTuberの歌声との出会いを探すデータベース",
							url: "https://vsinger-moment.jp",
							potentialAction: {
								"@type": "SearchAction",
								target: {
									"@type": "EntryPoint",
									urlTemplate:
										"https://vsinger-moment.jp/?q={search_term_string}",
								},
								"query-input": "required name=search_term_string",
							},
						}),
					}}
				/>
				<GoogleAnalytics gaTrackingId={env.GA_TRACKING_ID} />
			</head>
			<body className="flex flex-col min-h-screen">
				<SupabaseProvider env={env}>
					<Header />
					<main className={`flex-grow pb-16 ${position()}`}>
						<Outlet />
					</main>
					<Footer />
					<GlobalVideoPlayer />
				</SupabaseProvider>
				<ScrollRestoration />
				<Scripts />
				<SpeedInsights />
				<Analytics />
				<LiveReload />
			</body>
		</html>
	);
}

function App() {
	const [currentVideo, setCurrentVideo] = useState<{
		videoId: string;
		title: string;
		timestamp?: number;
	} | null>(null);

	return (
		<VideoPlayerProvider>
			<AppContent />
		</VideoPlayerProvider>
	);
}

export default withSentry(App);
